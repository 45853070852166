@font-face {
    font-family: 'MICR';
    src: url('https://pg-prod-bucket-1.s3.amazonaws.com/assets/micr-encoding.regular.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Poppins', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.MuiPopover-paper {
    overflow: initial !important;
}
.MuiPopover-paper::before {
    content: '';
    position: absolute;
    right: 16px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 9px 10px 8px;
    border-color: transparent transparent white transparent;
    z-index: 9998;
}
.MuiAlert-message {
    font-family: 'Roboto';
}
canvas {
    width: 100% !important;
}
